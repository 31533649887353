import {firebaseApp} from '../../firebaseConfig';
import 'firebase/auth';
import db from '../../firebaseConfig';
import {
  collection,
  query,
  orderBy,
  startAfter,
  limit,
  getDocs,
  where,
} from 'firebase/firestore';

/**
* get users credentials
*
* @return {Promise} A promise that returns users
*/
export function getPartnersList(lastName) {
  return new Promise((resolve) => {
    resolve(getPartners(lastName));
  })
}

const getPartners = async(lastName) => {
  let getPartnersQuery = '';
  if (lastName) {
    getPartnersQuery = query(collection(db, "partners"),
      orderBy("createdAt", 'desc'),
      startAfter(lastName),
      limit(10));
  } else {
    getPartnersQuery = query(
      collection(db, "partners"),
      orderBy("createdAt", 'desc'),
      limit(10)
    );
  }
  const documentSnapshots = await getDocs(getPartnersQuery);
  const partners = [];
  documentSnapshots.forEach((doc) => {
    const data = doc.data();
    partners.push({
      id: doc.id,
      partnerUid: data.partnerUid,
      name: data.name,
      mobile: data.mobile,
      alternativeMobile: data.alternativeMobile,
      customerCareNumber: data.customerCareNumber,
      locationId: data.locationId,
    });
  });
  return partners;
};

/**
* get users credentials
*
* @return {Promise} A promise that returns users
*/
export function getPartnersWithUid(uids) {
  return new Promise((resolve) => {
    resolve(fetchPartners(uids));
  })
}

const fetchPartners = async(uids) => {
  const getPartnersQuery = query(collection(db, "partners"),
        where("partnerUid", 'in', uids));
  const documentSnapshots = await getDocs(getPartnersQuery);
  const partners = [];
  documentSnapshots.forEach((doc) => {
    const data = doc.data();
    partners.push({
      id: doc.id,
      partnerUid: data.partnerUid,
      name: data.name,
      mobile: data.mobile,
      alternativeMobile: data.alternativeMobile,
      customerCareNumber: data.customerCareNumber,
      locationId: data.locationId,
    });
  });
  return partners;
};
