import {useContext} from 'react';
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {registerUser, loginUser, logoutUser, getUsersList} from './userAPI';
import {AuthContext} from './AuthContext';

const persistedState = JSON.parse(localStorage.getItem('persist:root')) || [];
const persistedUser = persistedState.user ?
  JSON.parse(persistedState.user) :
  {};
const persistedUid = persistedUser.uid ? persistedUser.uid : null;

const initialState = {
  status: 'idle',
  uid: persistedUid,
  usersList: [],
  loginStatus: 'idle',
};

export const login = createAsyncThunk(
    'user/login',
    async ({email, password}) => {
      try {
        const user = await loginUser(email, password);
        return user;
      } catch (error) {
        throw new Error('Login failed. Please try again.');
      }
    },
);

export const registration = createAsyncThunk(
    'user/register',
    async ({email, password}) => {
      return await registerUser(email, password);
    },
);

export const logout = createAsyncThunk(
    'user/logout',
    async () => {
      return await logoutUser();
    },
);

export const getUsers = createAsyncThunk(
  'user/getUsers',
  async(lastName) => {
    const users = await getUsersList(lastName);
    return users;
  }
);

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserId: (state, action) => {
      state.uid = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
        .addCase(login.pending, (state) => {
          state.loginStatus = 'loading';
        })
        .addCase(login.fulfilled, (state, action) => {
          state.loginStatus = 'fulfilled';
          state.uid = action.payload.uid;
        })
        .addCase(registration.pending, (state) => {
          state.status = 'loading';
        })
        .addCase(registration.fulfilled, (state, action) => {
          state.status = 'idle';
          state.uid = action.payload.uid;
        })
        .addCase(logout.pending, (state) => {
          state.status = 'loading';
        })
        .addCase(logout.fulfilled, (state) => {
          state.status = 'idle';
          state.uid = null;
        })
        .addCase(getUsers.pending, (state) => {
          state.status = 'loading';
        })
        .addCase(getUsers.fulfilled, (state, action) => {
          const uniqueUsers = [...state.usersList, ...action.payload];
          // Remove duplicates based on a unique identifier, e.g., user ID
          const uniqueUsersSet = new Set(uniqueUsers.map(user => user.id));
          const uniqueUserObjects = Array.from(uniqueUsersSet).map(userId => uniqueUsers.find(user => user.id === userId));
          state.usersList = uniqueUserObjects;
          state.status = 'idle';
        });
  },
});

export const uid = (state) => state.user.uid;
export const useAuth = () => {
  const authContext = useContext(AuthContext);
  return authContext ? authContext.currentUser : null;
};
export const {setUserId} = userSlice.actions;
export const usersList = (state) => state.user.usersList;
export const loginStatus = (state) => state.user.loginStatus;
export default userSlice.reducer;
