import * as React from 'react';
import {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { selectedPage }  from '../features/page/pageSlice';
import Dashboard from './Dashboard/Dashboard';
import Orders from './Orders/Orders';
import Reports from './Reports/Reports';
import Inventory from './Inventory/Inventory';
import OrderDetails from './Orders/OrderDetails';
import Customers from './Customers/Customers';
import Locations from './Locations/Locations';
import Cart from './Cart/Cart';
import CartDetails from './Cart/CartDetails';
import Partners from './Partners/Partners';

import Menu from './Menu';
import SignIn from './Login/Login';
import {useAuth, uid} from '../features/user/userSlice';
import {isAdmin, checkIfAdmin} from '../features/admin/adminSlice';
const isMobile = window.innerWidth <= 768;

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        Green Yogi
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

const mdTheme = createTheme();

function MainContent() {
  const [open, setOpen] = useState(!isMobile);
  const dispatch = useDispatch();
  const toggleDrawer = () => {
    setOpen(!open);
  };

  const user = useAuth();
  const authUserId = user ? user.uid : null;
  useEffect(() => {
    if(user) {
      dispatch(checkIfAdmin(authUserId));
    }
  }, [authUserId]);

  const currentPage = useSelector(selectedPage);
  const userId = useSelector(uid);
  const isAdminUser = useSelector(isAdmin);
  let title = currentPage;

  const selectedPageContent = (selection) => {
    if (selection === 'MAIN') {
      return <Dashboard/>
    } else if (selection === 'ORDERS') {
      return <Orders />;
    } else if (selection === 'REPORTS') {
      return <Reports />;
    } else if (selection === 'INVENTORY') {
      return <Inventory />;
    } else if (selection === 'ORDER_DETAILS') {
      return <OrderDetails />;
    } else if (selection === 'CUSTOMERS') {
      return <Customers />;
    } else if (selection === 'PARTNERS') {
      return <Partners />;
    } else if (selection === 'LOCATIONS') {
      return <Locations />;
    } else if (selection === 'CART') {
      return <Cart />
    } else if (selection === 'CART_DETAILS') {
      return <CartDetails />
    } else {
      return null;
    }
  }

  return (
    <>
    {authUserId && isAdminUser ?
      (
        <ThemeProvider theme={mdTheme}>
          <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar position="absolute" open={open}>
              <Toolbar
                sx={{
                  pr: '24px', // keep right padding when drawer closed
                }}
              >
                <IconButton
                  edge="start"
                  color="inherit"
                  aria-label="open drawer"
                  onClick={toggleDrawer}
                  sx={{
                    marginRight: '36px',
                    ...(open && { display: 'none' }),
                  }}
                >
                  <MenuIcon />
                </IconButton>
                <Typography
                  component="h1"
                  variant="h6"
                  color="inherit"
                  noWrap
                  sx={{ flexGrow: 1 }}
                >
                  {title}
                </Typography>
                <IconButton color="inherit">
                  <Badge badgeContent={4} color="secondary">
                    <NotificationsIcon />
                  </Badge>
                </IconButton>
              </Toolbar>
            </AppBar>
            <Drawer variant="permanent" open={open}>
              <Toolbar
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  px: [1],
                }}
              >
                <IconButton onClick={toggleDrawer}>
                  <ChevronLeftIcon />
                </IconButton>
              </Toolbar>
              <Divider />
              <Menu/>
            </Drawer>
            <Box
              component="main"
              sx={{
                backgroundColor: (theme) =>
                  theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[900],
                flexGrow: 1,
                height: '100vh',
                overflow: 'auto',
              }}
            >
              <Toolbar />
              <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                {selectedPageContent(currentPage)}
              </Container>
              <Copyright sx={{ pt: 4 }} />
            </Box>
          </Box>
        </ThemeProvider>
      ) : (
        <SignIn/>
      )}
      </>
  );
}

export default function Main() {
  return <MainContent />;
}
