import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {BsFillShieldLockFill} from 'react-icons/bs';
import {CgSpinner} from 'react-icons/cg';
import OtpInput from 'otp-input-react';
import {useState, useEffect} from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import {firebaseApp} from '../../firebaseConfig';
import {
  getAuth,
} from 'firebase/auth';
import {
  login,
  loginStatus,
  uid,
} from '../../features/user/userSlice';
import {checkIfAdmin} from '../../features/admin/adminSlice';

/**
 * Component that displays the login page
 *
 * @return {JSX.Element} Login component JSX
 */
export default function LoginEmail() {
  const auth = getAuth(firebaseApp);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showLoader, setShowLoader] = useState(false);
  const dispatch = useDispatch();
  
  const status = useSelector(loginStatus);
  const user = useSelector(uid);
  const errorLogin = "";
  const loginErrorMsg = "";

  useEffect(() => {
    if(user) {
      dispatch(checkIfAdmin(user));
    }
  }, [status, user]);


  const disableLogin = () => {
    return false;
  };

  const handleLogin = () => {
    dispatch(login({email, password}));
  };

  const handleForgotPassword = () => {

  };

  return (
    <section className="">
      <div className='login-email'>
        {errorLogin ? loginErrorMsg : null}
        <h2>Login</h2>
        <form>
          <input
            type="text"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <button
            type="button"
            onClick={handleLogin}
            disabled={disableLogin()}
          >
            Login
          </button>
          <a
            className="btn-forgot-password"
            onClick={handleForgotPassword}
          >
            Forgot Password
          </a>
        </form>
      </div> 
    </section>
  );
}
