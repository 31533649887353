import * as React from 'react';
import {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import Container from '@mui/material/Container';
import { DataGrid } from '@mui/x-data-grid';
import Button  from '@mui/material/Button';
import { getPartners, partnersList } from '../../features/partner/partnerSlice';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import './Partners.css';
import { selectPage }  from '../../features/page/pageSlice';

function UserContent() {
  const dispatch = useDispatch();
  const [firstRow, setFirstRow] = useState(0);

  useEffect(() => {
    dispatch(getPartners());
  }, []);

  const users = useSelector(partnersList);
  const currentPageUsers = users.slice(firstRow, firstRow + 10);

  const columns = [
    {
      field: "View Orders",
      renderCell: (cellValues) => {
        return (
          <Button
            variant="contained"
            color="primary"
            onClick={(event) => {
              handleClick(event, cellValues);
            }}
          >
            Orders
          </Button>
        );
      }
    },
    { field: 'name', headerName: 'Name', width: 250 },
    { field: 'email', headerName: 'Email', width: 200 },
    { field: 'mobile', headerName: 'Mobile', width: 100 },
    { field: 'address', headerName: 'Address', width: 350 },
  ];

  const handleClick = (event, cellValues) => {
    dispatch(selectPage({page: 'ORDERS', data : {type: 'customer_order', data: cellValues.row}}));
  };

  const handleBackClick = () => {
    setFirstRow(firstRow > 0 ? firstRow - 10 : 0);
  };

  const handleForwardClick = () => {
    if (users.length > 0) {
      const lastUser = users[users.length - 1];
      dispatch(getPartners(lastUser.createdAt));
      setFirstRow(firstRow + 10); 
    }
  };
  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }} className='inventory'>
      <DataGrid rows={currentPageUsers} columns={columns} />
      <div className="pagination">
        <ArrowBackIosIcon onClick={handleBackClick} className={firstRow === 0 ? 'disabled' : ''} />
        <ArrowForwardIosIcon onClick={handleForwardClick} className={currentPageUsers.length < 10 ? 'disabled' : ''} />
      </div>
    </Container>
  );
}

export default function Inventory() {
  return <UserContent />;
}
