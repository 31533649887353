import db from '../../firebaseConfig';
import {
  collection,
  getDocs,
  query,
  where,
} from 'firebase/firestore';

const getAdminId = async (uid) => {
  try {
    const res = [];
    const q = query(
        collection(
            db,
            'admin',
        ),
        where('uid', '==', uid),
    );
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => res.push(doc.data()));
    if( res.length > 0 ) {
      return res[0].uid === uid ? true : false;
    }
    return false;
  } catch (error) {
    throw new Error('Login failed. Please try again.');
  }
};

/**
 * Fetches all locations.
 *
 * @return {Promise} A promise that resolves with the locations.
 */
export function getAdmin(uid) {
  return new Promise((resolve) =>
    resolve(getAdminId(uid)),
  );
}
