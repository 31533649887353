import * as React from 'react';
import {useSelector} from 'react-redux';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import {cartForTheDate} from '../../features/cart/cartSlice';
import { selectedData }  from '../../features/page/pageSlice';
import Button  from '@mui/material/Button';

function CartDetailsContent() {
  const cartData = useSelector(cartForTheDate);
  const cartId = useSelector(selectedData);
  const selectedCart = cartData.find((cart) => cart.id === cartId);

  let selectedItems = [];
  let status = '';
  let addresses = [];
  let createdAt = '';
  if (selectedCart) {
    selectedItems = selectedCart.cart ? selectedCart.cart : [];
    addresses = selectedCart.addresses ? selectedCart.addresses : [];
    createdAt = selectedCart.createdAt ? selectedCart.createdAt : '';
    status = selectedCart.status ? selectedCart.status : 'Created';
  }

  const address = addresses.length > 0 ? addresses[0] : {};

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text.secondary,
    'box-shadow': 'none',
    /*border: '1px solid #eee',*/
    margin: theme.spacing(.5),
  }));

  const handleClick = () => {
    // Clone the content you want to print
    const printableDiv = document.getElementById('printablediv').cloneNode(true);

    // Create a new window with the cloned content
    const printWindow = window.open('', '', 'width=600,height=600');
    printWindow.document.open();
    printWindow.document.write('<html><head><title>Green Yogi Order</title></head><body>');
    printWindow.document.write(printableDiv.innerHTML);
    printWindow.document.write('</body></html>');
    printWindow.document.close();

    // Print the new window
    printWindow.print();

    // Close the new window after printing
    printWindow.close();
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4, lg:4 }} className='bill-container'>
        <Button onClick={handleClick}>Print</Button>
        <div id="printablediv">
            <h2>Green Yogi</h2>
            <h3>Status : {status}</h3>
            <h5>
                {address.name}, {address.flatNo}, {address.apartmentName}, {address.addressLine1}, {address.addressLine2}
            </h5>
            <h5>Mobile number {address.phoneNumber}</h5>
            <Grid container spacing={4}>
                <Grid item xs={6}>
                    <Item><b>Title</b></Item>
                </Grid>
                <Grid item xs={3}>
                    <Item><b>Price and count</b></Item>
                </Grid>
                <Grid item xs={3}>
                    <Item><b>Total</b></Item>
                </Grid>
            </Grid>
            {selectedItems.map((item) => (
                <Grid container spacing={4}>
                    <Grid item xs={6}>
                        <Item>{item.title} {item.optionTitle}</Item>
                    </Grid>
                    <Grid item xs={3}>
                        <Item>{item.sellPrice} x {item.count}</Item>
                    </Grid>
                    <Grid item xs={3}>
                        <Item>{item.sellPrice * item.count}</Item>
                    </Grid>
                </Grid>
            ))}
        </div>
    </Container>
  );
}

export default function CartDetails() {
  return <CartDetailsContent />;
}
