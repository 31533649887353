import React, { useState } from 'react';
import UpdateInventory from './UpdateInventory';
import ImportFromExcel from './ImportFromExcel';
import Title from '../Common/Title';
import Button  from '@mui/material/Button';
import Container from '@mui/material/Container';

/**
 * Component that displays the inventory management page
 *
 * @return {JSX.Element} Inventory component JSX
 */
export default function Inventory() {
  const [showUpdateInventory, setShowUpdateInventory] = useState(false);
  const [showImportFromExcel, setShowImportFromExcel] = useState(false);

  const styleButton = {
    marginRight: '10px',
  }

  return (
    <>
      <Container>
        <Title>Manage Daily Inventory</Title>
        <Button
          sx={styleButton}
          variant={showImportFromExcel ? "contained" : "outlined"}
          onClick={() => { setShowImportFromExcel(true); setShowUpdateInventory(false); }}
        >Import from Excel</Button>
        <Button
          sx={styleButton}
          variant={showUpdateInventory ? "contained" : "outlined"}
          onClick={() => { setShowUpdateInventory(true); setShowImportFromExcel(false); }}
        >Update stock</Button>
        {showUpdateInventory && <UpdateInventory />}
        {showImportFromExcel && <ImportFromExcel />}
      </Container>
    </>
  );
}
