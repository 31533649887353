import * as React from 'react';
import {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import Container from '@mui/material/Container';
import {getLocations, allLocations} from '../../features/locations/locationSlice';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';

function UserContent() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getLocations());
  }, []);

  const locations = useSelector(allLocations);
  const [selectedLocation, setSelectedLocation] = useState('');

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const handleLocationChange = (event) => {
    setSelectedLocation(event.target.value);
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }} className='inventory'>
        <FormControl sx={{ m: 1, width: 300 }}>
              <InputLabel id="demo-multiple-name-label">Select a Location</InputLabel>
              <Select
                labelId="demo-multiple-name-label"
                id="demo-multiple-name"
                input={<OutlinedInput label="Select a Location" />}
                MenuProps={MenuProps}
                value={selectedLocation}
                onChange={handleLocationChange}
              >
                {locations.map((location) => (
                  <MenuItem
                    key={location.id}
                    value={location.id}
                  >
                    {location.apartmentName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
    </Container>
  );
}

export default function Inventory() {
  return <UserContent />;
}
