import React from 'react';
import {store} from './app/store';
import {Provider} from 'react-redux';
import './App.css';
import {AuthProvider} from './features/user/AuthContext';
import Main from './components/Main';

function App() {
  return (
    <AuthProvider>
      <Provider store={store}>
        <Main/>
      </Provider>
    </AuthProvider>
  );
}

export default App;
