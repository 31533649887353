import * as React from 'react';
import {useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from '../Common/Title';
import {getOrderForDate, todaysOrder} from '../../features/order/orderSlice';

// Generate Order Data
function createData(id, date, name, shipTo, paymentMethod, amount) {
  return { id, date, name, shipTo, paymentMethod, amount };
}

function preventDefault(event) {
  event.preventDefault();
}

export default function Orders() {
  const dispatch = useDispatch();
  useEffect(() => {
    const now = new Date();
    const fromDate = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const toDate = new Date(fromDate);
    toDate.setDate(toDate.getDate() + 1);
    dispatch(getOrderForDate({fromDate, toDate}));
  }, [dispatch]);
  const orders = useSelector(todaysOrder);
  const rows = [];
  orders.forEach((doc) => {
    const orderDate = doc.createdAt.toDate();
    const formattedDate = orderDate.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' });
    const formattedTime = orderDate.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
    const formattedDateTime = `${formattedDate} ${formattedTime}`;
    rows.push(createData(
      doc.id,
      formattedDateTime,
      doc.address.name,
      doc.address.name,
      'COD',
      doc.total_price_final,
    ));
  });
  
  return (
    <React.Fragment>
      <Title>Today's Orders</Title>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Ship To</TableCell>
              <TableCell>Payment Method</TableCell>
              <TableCell align="right">Sale Amount</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.id}>
                <TableCell>{row.date}</TableCell>
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.shipTo}</TableCell>
                <TableCell>{row.paymentMethod}</TableCell>
                <TableCell align="right">{`₹${row.amount}`}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Link color="primary" href="#" onClick={preventDefault} sx={{ mt: 3 }}>
        See more orders
      </Link>
    </React.Fragment>
  );
}
