import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {getPartnersList, getPartnersWithUid} from './partnerAPI';

const initialState = {
  status: 'idle',
  partnersList: [],
  partners: [],
};

export const getPartners = createAsyncThunk(
  'partner/getPartners',
  async(lastName) => {
    const partnersList = await getPartnersList(lastName);
    return partnersList;
  }
);

export const getPartnersByUid = createAsyncThunk(
  'partner/getPartnersWithUid',
  async(uids) => {
    return await getPartnersWithUid(uids);
  }
);

export const partnerSlice = createSlice({
  name: 'partner',
  initialState,
  reducers: {
    setUserId: (state, action) => {
      state.uid = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
        .addCase(getPartners.pending, (state) => {
          state.status = 'loading';
        })
        .addCase(getPartners.fulfilled, (state, action) => {
          const uniquePartners = [...state.partnersList, ...action.payload];
          // Remove duplicates based on a unique identifier, e.g., partner ID
          const uniquePartnersSet = new Set(uniquePartners.map(partner => partner.id));
          const uniquePartnerObjects = Array.from(uniquePartnersSet).map(partnerUid => uniquePartners.find(partner => partner.id === partnerUid));
          state.partnersList = uniquePartnerObjects;
          state.status = 'idle';
        })
        .addCase(getPartnersByUid.pending, (state, action) => {
          state.status = 'pending';
        })
        .addCase(getPartnersByUid.fulfilled, (state, action) => {
          state.partners = action.payload;
        });
  },
});

export const partnersList = (state) => state.partner.partnersList;
export const partners = (state) => state.partner.partners;
export default partnerSlice.reducer;
