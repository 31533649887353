import * as React from 'react';
import {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import Container from '@mui/material/Container';
import Button  from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { Select, MenuItem, TextField, Stack } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import CircularProgress from '@mui/material/CircularProgress';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import './Inventory.css';
import {
  stockFetchStatus,
  saveDailyUpdates,
  productStocks,
  getDefaultProducts,
  updateStatus,
} from '../../features/products/productSlice';
import {
  getSuppliers,
  suppliersList
} from '../../features/suppliers/suppliersSlice';

function InventoryContent() {
  const dispatch = useDispatch();

  const fetchStatus = useSelector(stockFetchStatus);
  const stockProducts = useSelector(productStocks);
  const suppliers = useSelector(suppliersList);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedProductId, setSelectedProductId] = useState("");
  const [selectedProductName, setSelectedProductName] = useState("");
  const [selectedSupplierId, setSelectedSupplierId] = useState("");
  const [selectedSupplierName, setSelectedSupplierName] = useState("");
  const [productQty, setProductQty] = useState(0);
  const [productPP, setProductPP] = useState(0);
  const [productSP, setProductSP] = useState(0);
  const [purchaseData, setPurchaseData] = useState([]);
  const [supplierPayment, setSupplierPayment] = useState(0);

  const styleStack = {
    marginTop: '20px',
  };

  const styleDatePicker = {
    marginTop: '20px',
  }

  useEffect(() => {
    if(fetchStatus === 'fulfilled') {
      // setFormData(stockProducts);
    }
  }, [dispatch, fetchStatus]);

  const handleProductChange = (input) => {
    setSelectedProductId(input);

  };

  const handleSupplierChange = (input) => {
    setSelectedSupplierId(input);
  };

  const handleInputChange = (item, input) => {
    switch(item) {
      case "qty":
        setProductQty(input);
        break;
      case "purchasePrice":
        setProductPP(input);
        break;
      case "salesPrice":
        setProductSP(input);
        break;
      case "supplierPayment":
        setSupplierPayment(input);
        break;
    }
  };

  const handleProductIdChange = (id) => {
    const product = stockProducts.find(stockProduct => stockProduct.id === parseInt(id));
    const productName = product ? product.product_name : '';
    setSelectedProductId(parseInt(id));
    setSelectedProductName(productName);
  };

  const handleAddPurchase = () => {
    const product = stockProducts.find(stockProduct => stockProduct.id === selectedProductId);
    const productName = product ? product.product_name : '';
    const supplier = suppliers.find(supplier => supplier.id === selectedSupplierId);
    const supplierName = supplier ? supplier.name : '';
    const rate = Number((productPP/productQty).toFixed(2));
    const totalSalesPrice = Number((productSP * productQty).toFixed(2));
    const profit = productQty && productPP && productSP ? (((productSP - rate) / rate) * 100).toFixed(2) + '%' : 0;

    if(
      productName === '' ||
      supplier === '' ||
      supplierName === '' ||
      !(rate > 0) ||
      !(totalSalesPrice > 0) ||
      profit === 0
    ) {
      alert("please enter all values");
    } else {
      const newPurchase = {
        id: selectedProductId,
        product_name: productName,
        profit,
        purchasePrice: Number(productPP),
        qty: Number(productQty),
        rate,
        salesPrice: Number(productSP),
        totalSalesPrice,
        supplierName,
        supplierId: selectedSupplierId,
        supplierPayment,
      };
      setPurchaseData(prevData => [...prevData, newPurchase]);
      setSelectedProductId(0);
      setProductQty(0);
      setProductSP(0);
      setProductPP(0);
      setSupplierPayment(0);
    }
  };

  const handleSavePurchases = () => {
    dispatch(saveDailyUpdates({
      docId: selectedDate.format("DD_MM_YYYY"),
      dailyUpdates: purchaseData,
    }));
  };

  return (
    <>
      <Box>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            sx={styleDatePicker}
            value={selectedDate}
            onChange={(newValue) => {
              dispatch(getSuppliers());
              dispatch(getDefaultProducts());
              setSelectedDate(newValue);
            }}
            format="DD-MM-YYYY"
          />
        </LocalizationProvider>
        {selectedDate &&
          <>
            <Stack direction="row" spacing={2} sx={styleStack}>
              <TextField
                required
                id="outlined-required"
                label={selectedProductName ? selectedProductName : "ID"}
                value={selectedProductId}
                onChange={(e) =>
                  handleProductIdChange(e.target.value)
                }
              />
            </Stack>
            <Stack direction="row" spacing={2} sx={styleStack}>
              <Select
                labelId="supplier-select-label"
                id="supplier-select"
                label="Supplier"
                value={selectedSupplierId}
                onChange={(e) =>
                  handleSupplierChange(e.target.value)
                }
              >
                {suppliers.map((supplier) => (
                  <MenuItem key={supplier.id} value={supplier.id}>
                    {supplier.name}
                  </MenuItem>
                ))}
              </Select>
            </Stack>    
            <Stack direction="row" spacing={2} sx={styleStack}>
              <TextField
                required
                id="outlined-required"
                label="Quantity"
                value={productQty}
                onChange={(e) =>
                  handleInputChange('qty', e.target.value)
                }
                type="number"
              />
              <TextField
                required
                id="outlined-required"
                label="Purchase Price"
                value={productPP}
                onChange={(e) =>
                  handleInputChange('purchasePrice', e.target.value)
                }
                type="number"
              />
              <TextField
                required
                id="outlined-required"
                label="Sales Price"
                value={productSP}
                onChange={(e) =>
                  handleInputChange('salesPrice', e.target.value)
                }
                type="number"
              />
            </Stack>
            <Stack spacing={2} sx={styleStack}>
              <FormGroup>
                <FormControlLabel required control={<Checkbox />} label="Is this a credit purchase" />
              </FormGroup>  
              <TextField
                required
                id="outlined-required"
                label="Amount Payed to Supplier"
                value={supplierPayment}
                onChange={(e) =>
                  handleInputChange('supplierPayment', e.target.value)
                }
                type="number"
              />
            </Stack>
            <Stack direction="row" spacing={2} sx={styleStack}>
              {purchaseData.length > 0 &&
                <Button
                  variant="outlined"
                  onClick={() => handleSavePurchases()}
                >Save {purchaseData.length} Purchase{purchaseData.length > 1 ? "s" : ""}</Button>
              }
              <Button 
                variant={purchaseData.length > 0 ? "contained" : "outlined"}
                onClick={() => handleAddPurchase()}
              >ADD</Button>
            </Stack>
          </>
        }
      </Box>
      <Box>
        {purchaseData.length > 0 &&
          <table className='purchase-table'>
            <tr>
              <th>Name</th>
              <th>total PP</th>
              <th>total SP</th>
              <th>Qty</th>
              <th>PP</th>
              <th>SP</th>
              <th>Profit</th>
              <th>Supplier</th>
            </tr>
            {purchaseData.map((purchase) => (
              <tr key={purchase.id}>
                <td>{purchase.product_name}</td>
                <td>{purchase.purchasePrice}</td>
                <td>{purchase.totalSalesPrice}</td>
                <td>{purchase.qty}</td>
                <td>{purchase.rate}</td>
                <td>{purchase.salesPrice}</td>
                <td>{purchase.profit}</td>
                <td>{purchase.supplierName}</td>
              </tr>
            ))}
          </table>
        }
      </Box>
    </>
  );
}

export default function InventoryForm1() {
  return <InventoryContent />;
}
