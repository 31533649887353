import * as React from 'react';
import {useDispatch} from 'react-redux';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import PeopleIcon from '@mui/icons-material/People';
import BarChartIcon from '@mui/icons-material/BarChart';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LayersIcon from '@mui/icons-material/Layers';
import AssignmentIcon from '@mui/icons-material/Assignment';
import InventoryIcon from '@mui/icons-material/Inventory';
import HandshakeIcon from '@mui/icons-material/Handshake';
import LogoutIcon from '@mui/icons-material/Logout';

import { selectPage }  from '../features/page/pageSlice';
import { logout } from '../features/user/userSlice';

function MenuContent() {
  const dispatch = useDispatch();
  const setPage = (item) => {
    dispatch(selectPage({ page : item }));
  }

  return (
    <List component="nav">
        <React.Fragment>
            <ListItemButton onClick={()=>setPage('MAIN')}>
                <ListItemIcon>
              <DashboardIcon />
                    </ListItemIcon>
                <ListItemText primary="Dashboard" />
            </ListItemButton>
            <ListItemButton onClick={()=>setPage('ORDERS')}>
                <ListItemIcon>
                    <LocalShippingIcon />
                </ListItemIcon>
                <ListItemText primary="Orders" />
            </ListItemButton>
            <ListItemButton onClick={()=>setPage('CART')}>
                <ListItemIcon>
                    <ShoppingCartIcon />
                </ListItemIcon>
                <ListItemText primary="Cart" />
            </ListItemButton>
            <ListItemButton onClick={()=>setPage('INVENTORY')}>
                <ListItemIcon>
                    <InventoryIcon />
                </ListItemIcon>
                <ListItemText primary="Inventory" />
            </ListItemButton>
            <ListItemButton onClick={()=>setPage('PARTNERS')}>
                <ListItemIcon>
                    <HandshakeIcon />
                </ListItemIcon>
                <ListItemText primary="Partners" />
            </ListItemButton>
            <ListItemButton onClick={()=>setPage('CUSTOMERS')}>
                <ListItemIcon>
                    <PeopleIcon />
                </ListItemIcon>
                <ListItemText primary="Customers" />
            </ListItemButton>
            <ListItemButton onClick={()=>setPage('REPORTS')}>
                <ListItemIcon>
                    <BarChartIcon />
                </ListItemIcon>
                <ListItemText primary="Reports" />
            </ListItemButton>
            <ListItemButton onClick={()=>setPage('LOCATIONS')}>
                <ListItemIcon>
                    <LocationOnIcon />
                </ListItemIcon>
                <ListItemText primary="Locations" />
            </ListItemButton>
        </React.Fragment>
        <Divider sx={{ my: 1 }} />
        <React.Fragment>
            <ListSubheader component="div" inset>
            Saved reports
          </ListSubheader>
          <ListItemButton>
            <ListItemIcon>
              <AssignmentIcon />
            </ListItemIcon>
            <ListItemText primary="Current month" />
            </ListItemButton>
          <ListItemButton>
            <ListItemIcon>
              <AssignmentIcon />
            </ListItemIcon>
            <ListItemText primary="Last quarter" />
          </ListItemButton>
          <ListItemButton>
            <ListItemIcon>
              <AssignmentIcon />
            </ListItemIcon>
            <ListItemText primary="Year-end sale" />
          </ListItemButton>
          <ListItemButton onClick={()=> dispatch(logout())}>
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItemButton>
        </React.Fragment>
    </List>
  );
}

export default function Menu() {
  return <MenuContent />;
}
