import * as React from 'react';
import {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Title from '../Common/Title';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import {getOrderForDate, orderForTheDate} from '../../features/order/orderSlice';
import {getLocations, allLocations} from '../../features/locations/locationSlice';

function ReportsContent() {
  const dispatch = useDispatch();

  const handleDateChange = (date) => {
    const now = new Date(date);
    const fromDate = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const toDate = new Date(fromDate);
    toDate.setDate(toDate.getDate() + 1);
    dispatch(getOrderForDate({fromDate, toDate}));
  }

  useEffect(() => {
    dispatch(getLocations());
    const now = new Date();
    const fromDate = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const toDate = new Date(fromDate);
    toDate.setDate(toDate.getDate() + 1);
    dispatch(getOrderForDate({fromDate, toDate}));
  }, [dispatch]);

  const orders = useSelector(orderForTheDate);
  const locations = useSelector(allLocations);
  const [selectedLocation, setSelectedLocation] = useState('');

  const filteredOrdersItems = (orders, selectedLocation) => {
    const ordersForLocation = orders.filter((order) => order.address.locationId === selectedLocation);
    return ordersForLocation.flatMap(order => order.items);
  };

  const items = selectedLocation ? filteredOrdersItems(orders, selectedLocation) : orders.flatMap(order => order.items);

  const consolidatedItems = Object.values(items.reduce((acc, { optionId, ...rest }) => {
    if (!acc[optionId]) {
      acc[optionId] = { ...rest, count: 1 };
    } else {
      acc[optionId].count =  acc[optionId].count + rest.count;
    }
    return acc;
  }, {}));

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const handleLocationChange = (event) => {
    setSelectedLocation(event.target.value);
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Title>Consolidated Order for Selected Date</Title>
      <Grid container spacing={3}>
        {/* Recent Orders */}
        <Grid item xs={12} md={8} lg={4}>
          <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', mb: 1 }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                defaultValue={dayjs()}
                format="DD/MM/YYYY"
                label="Select a date"
                inputFormat="DD/MM/YYYY"
                onChange={handleDateChange}
              />
            </LocalizationProvider>
          </Paper>
        </Grid>
        <Grid item xs={12} md={8} lg={4}>
          <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', mb: 1 }}>
            <FormControl sx={{ m: 1, width: 300 }}>
              <InputLabel id="demo-multiple-name-label">Select a Location</InputLabel>
              <Select
                labelId="demo-multiple-name-label"
                id="demo-multiple-name"
                input={<OutlinedInput label="Select a Location" />}
                MenuProps={MenuProps}
                value={selectedLocation}
                onChange={handleLocationChange}
              >
                {locations.map((location) => (
                  <MenuItem
                    key={location.id}
                    value={location.id}
                  >
                    {location.apartmentName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Paper>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        {/* Recent Orders */}
        <Grid item xs={12}>
          <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
            <TableContainer component={Paper}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Product</TableCell>
                    <TableCell>Option</TableCell>
                    <TableCell>Sell Price</TableCell>
                    <TableCell>Count</TableCell>
                    <TableCell align="right">Amount</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {consolidatedItems.map((row) => (
                    <TableRow key={row.optionId}>
                      <TableCell>{row.title}</TableCell>
                      <TableCell>{row.optionTitle}</TableCell>
                      <TableCell>{row.sellPrice}</TableCell>
                      <TableCell>{row.count}</TableCell>
                      <TableCell align="right">{`₹${row.sellPrice*row.count}`}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
}

export default function Reports() {
  return <ReportsContent />;
}
