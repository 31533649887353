import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {getAdmin} from './adminAPI';

const initialState = {
  isAdmin: false,
};

export const checkIfAdmin = createAsyncThunk(
    'admin/checkIfAdmin',
    async (uid) => {
      return await getAdmin(uid);
    },
);

export const adminSlice = createSlice({
  name: 'admin',
  initialState,
  // The `reducers` field lets us define reducers
  // and generate associated actions
  reducers: {},
  extraReducers: (builder) => {
    builder
        .addCase(checkIfAdmin.pending, (state) => {
          state.status = 'loading';
        })
        .addCase(checkIfAdmin.fulfilled, (state, action) => {
          state.status = 'fulfilled';
          state.isAdmin = action.payload;
        });
  },
});

export const isAdmin = (state) => state.admin.isAdmin;

export default adminSlice.reducer;
