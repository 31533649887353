import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {fetchAllOrders, fetchOrder, placeOrder} from './orderAPI';
import {updateProductAvailability} from '../products/productsAPI';

const initialState = {
  allOrders: [],
  status: 'idle',
  selectedOrder: {},
  currentOrder: {},
  createOrderStatus: 'idle',
  todaysOrder: [],
  orderForTheDate: [],
};

/**
 * get orders for customers.
 * @param {object} allOrders - The order object to save.
 * @return {Promise<void>} A promise that resolves when
 * the order has been saved.
 */
export const getAllOrders = createAsyncThunk(
    'order/fetchAllOrders',
    async (uid) => {
      const response = await fetchAllOrders('customer', uid);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    },
);

/**
 * Saves an order to the server.
 * @param {object} allOrders - The order object to save.
 * @return {Promise<void>} A promise that resolves when
 * the order has been saved.
 */

export const getOrderForDate = createAsyncThunk(
  'order/getOrderForDate',
  async ({fromDate, toDate}) => {
    const response = await fetchAllOrders('date', null, fromDate, toDate);
    // The value we return becomes the `fulfilled` action payload
    return response.data;
  },
);

/**
 * Saves an order to the server.
 * @param {object} selectedOrder - The order object to save.
 * @return {Promise<void>} A promise that resolves when the
 * order has been saved.
 */
export const getSelectedOrder = createAsyncThunk(
    'order/fetchOrder',
    async (orderId) => {
      return await fetchOrder(orderId);
    },
);

/**
 * Saves an order to the server.
 * @param {object} currentOrder - The order object to save.
 * @return {Promise<void>} A promise that resolves when the
 * order has been saved.
 */
export const saveOrder = createAsyncThunk(
    'order/placeOrder',
    async ({
      user,
      basket,
      address,
      totalCartPriceFinal,
      totalCartPrice,
      CartShippingCharge,
    }) => {
      const order = {
        phone_number: user.phoneNumber,
        uid: user.uid,
        items: basket,
        total_price_final: totalCartPriceFinal,
        total_price: totalCartPrice,
        shipping_charge: CartShippingCharge,
        address,
        status: 'created', // created, confirmed, fulfilled, canceled, refunded
      };
      const response = await placeOrder(order);
      if (response.status === 'success') {
        const optionIds = order.items.map((product) => product.optionId);
        await updateProductAvailability(optionIds);
        return response;
      } else {
      // update abandon cart
        return response;
      }
    },
);

export const orderSlice = createSlice({
  name: 'order',
  initialState,
  // The `reducers` field lets us define reducers
  // and generate associated actions
  reducers: {},
  extraReducers: (builder) => {
    builder
        .addCase(getAllOrders.pending, (state) => {
          state.status = 'loading';
        })
        .addCase(getAllOrders.fulfilled, (state, action) => {
          state.status = 'idle';
          state.allOrders = action.payload;
        })
        .addCase(getSelectedOrder.pending, (state) => {
          state.status = 'pending';
        })
        .addCase(getSelectedOrder.fulfilled, (state, action) => {
          state.status = 'fulfilled';
          state.selectedOrder = action.payload;
        })
        .addCase(saveOrder.pending, (state) => {
          state.createOrderStatus = 'pending';
        })
        .addCase(saveOrder.fulfilled, (state, action) => {
          state.createOrderStatus = 'created';
          state.currentOrder = action.payload.order;
        })
        .addCase(getOrderForDate.pending, (state) => {
          state.status = 'pending';
        })
        .addCase(getOrderForDate.fulfilled, (state, action) => {
          state.status = 'fulfilled';
          state.todaysOrder = action.payload;
          state.orderForTheDate = action.payload;
        });
  },
});

export const allOrders = (state) => state.order.allOrders;
export const selectedOrder = (state) => state.order.selectedOrder;
export const currentOrder = (state) => state.order.currentOrder;
export const createOrderStatus = (state) => state.order.createOrderStatus;
export const todaysOrder = (state) => state.order.todaysOrder;
export const orderForTheDate = (state) => state.order.orderForTheDate;

export default orderSlice.reducer;
