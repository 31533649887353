import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {
  fetchProducts,
  addProduct,
  updateDocument,
  addDailyUpdates,
  fetchProductStocks,
  fetchLatestProductStock,
} from './productsAPI';
import {
  productCacheExpiration,
  fixedShippingCharge,
} from '../../config/constants';

const persistedState = JSON.parse(localStorage.getItem('persist:root')) || [];
const persistedProducts = persistedState.products ?
  JSON.parse(persistedState.products) :
  {};
const persistedBasket = persistedProducts.basket ?
  persistedProducts.basket :
  [];
const persistedTotalPrice = persistedProducts.totalPrice;
const persistedTotalPriceAfterOffer = persistedProducts.totalPriceAfterOffer;
const persistedOfferPrice = persistedProducts.offerPrice;
const persistedTotalPriceFinal = persistedProducts.totalPriceFinal;
const persistedShippingCharge = persistedProducts.shippingCharge;

const initialState = {
  allProducts: [],
  allOptions: [],
  status: 'idle',
  updateStatus: 'idle',
  basket: persistedBasket,
  totalPrice: persistedTotalPrice,
  totalPriceAfterOffer: persistedTotalPriceAfterOffer,
  offerPrice: persistedOfferPrice,
  totalPriceFinal: persistedTotalPriceFinal,
  isFavorite: false,
  isEnterOfferCode: false,
  offerMessage: '',
  shippingCharge: persistedShippingCharge,
  selectedCategory: 'ALL',
  openHalfCart: false,
  searchKey: '',
  defaultProducts: [],
  latestProductStock: [],
  productStockForDate: [],
  productStocks: [],
  stockFetchStatus: 'idle',
};

const offerCode = {
  code: 'ABCD',
  disCount: 20,
};

const sumPrice = (items, isOffer) => {
  const totalPrice = items.reduce((totalPrice, product) => {
    return totalPrice + product.sellPrice * product.count;
  }, 0);

  if (isOffer) {
    const offerPrice = (totalPrice * offerCode.disCount) / 100;
    const totalPriceAfterOffer = totalPrice - offerPrice;

    return {
      totalPrice,
      offerPrice,
      totalPriceAfterOffer,
      ...sumPriceWithSend(totalPrice, offerPrice),
    };
  } else {
    return {
      totalPrice,
      ...sumPriceWithSend(totalPrice),
      shippingCharge: fixedShippingCharge,
    };
  }
};

// calc Price With shopping cost
const sumPriceWithSend = (totalPrice, offerPrice = 0) => {
  let totalPriceFinal = null;

  if (totalPrice - offerPrice <= 100_000) {
    totalPriceFinal = totalPrice + fixedShippingCharge - offerPrice;
  } else {
    totalPriceFinal = totalPrice - offerPrice;
  }

  return {totalPriceFinal};
};

export const getProducts = createAsyncThunk(
    'products/fetchProducts',
    async (category) => {
      if (persistedProducts.allProducts &&
        persistedProducts.allProducts.length > 0 &&
        persistedProducts.expiry &&
        persistedProducts.expiry > new Date().getTime()) {
        return {
          products: {
            data: persistedProducts.allProducts,
          },
          options: {
            data: persistedProducts.allOptions,
          },
          expiry: persistedProducts.expiry,
        };
      } else {
        const response = await fetchProducts(category);
        return {
          ...response.data,
          expiry: new Date().getTime() + productCacheExpiration,
        };
      }
    },
);

export const addToFirebaseCart = createAsyncThunk(
    'products/addToFirebaseCart',
    async (category) => {
    // const response = await fetchProducts(category);
    // The value we return becomes the `fulfilled` action payload
      return [];
    },
);

export const saveProduct = createAsyncThunk(
  'products/saveProduct',
  async (product) => {
    const response = await addProduct(product);
    return response;
  }
)

export const saveDailyUpdates = createAsyncThunk(
  'products/saveDailyUpdates',
  async ({docId, dailyUpdates}) => {
    //update the product quantity and price
    const response = await addDailyUpdates(docId, dailyUpdates);
    return response;
  }
);

export const updateProductOption = createAsyncThunk(
  'products/updateProductOption',
  async ({id, data}) => {
    const response = await updateDocument('product_options', id, data);
    return response;
  }
);

export const updateProduct = createAsyncThunk(
  'products/updateProduct',
  async ({id, data}) => {
    const response = await updateDocument('products', id, data);
    return response;
  }
);

export const getDefaultProducts = createAsyncThunk(
  'products/getDefaultProducts',
  async () => {
    return await fetchProductStocks('default');
  }
);

export const getProductsForDate = createAsyncThunk(
  'products/getProductsForDate',
  async (date) => {
    //remove the date for now, we will fetch default product and qty and prices from all products.
    //return await fetchProductStocks(date);
    return await fetchProductStocks();
  }
);

export const getLatestProductStock = createAsyncThunk(
  'products/getLatestProductStock',
  async () => {
    return await fetchLatestProductStock();
  }
)

export const productSlice = createSlice({
  name: 'products',
  initialState,
  // The `reducers` field lets us define reducers
  // and generate associated actions
  reducers: {
    addToCart: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.basket.push({...action.payload, count: 1});
      const prices = sumPrice(state.basket, state.isEnterOfferCode);
      for (const price in prices) {
        if (Object.prototype.hasOwnProperty.call(prices, price)) {
          state[price] = prices[price];
        }
      }
    },
    increaseCartQty: (state, action) => {
      const indexPlus = state.basket.findIndex(
          (product) => product.optionId === action.payload,
      );
      state.basket[indexPlus].count++;
      const prices = sumPrice(state.basket, state.isEnterOfferCode);
      for (const price in prices) {
        if (Object.prototype.hasOwnProperty.call(prices, price)) {
          state[price] = prices[price];
        }
      }
    },
    decreaseCartQty: (state, action) => {
      const indexMinus = state.basket.findIndex(
          (product) => product.optionId === action.payload,
      );
      if (state.basket[indexMinus].count > 1) {
        state.basket[indexMinus].count--;
        const prices = sumPrice(state.basket, state.isEnterOfferCode);
        for (const price in prices) {
          if (Object.prototype.hasOwnProperty.call(prices, price)) {
            state[price] = prices[price];
          }
        }
      } else {
        state.basket.splice(indexMinus, 1);
      }
    },
    clearCart: (state) => {
      state.basket = [];
    },
    selectCategory: (state, action) => {
      state.selectedCategory = action.payload;
    },
    showHalfCart: (state, action) => {
      state.openHalfCart = action.payload;
    },
    setSearchKey: (state, action) => {
      state.searchKey = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
        .addCase(getProducts.pending, (state) => {
          state.status = 'loading';
        })
        .addCase(getProducts.fulfilled, (state, action) => {
          state.status = 'idle';
          const payload = action.payload;
          state.allOptions = payload.options.data;
          state.allProducts = payload.products.data;
          state.expiry = payload.expiry;
        })
        .addCase(saveProduct.pending, (state) => {
          state.status = 'loading';
        })
        .addCase(saveProduct.fulfilled, (state, action) => {
          state.status = 'idle';
        })
        .addCase(updateProductOption.pending, (state, action) => {
          state.updateStatus = 'loading';
        })
        .addCase(updateProductOption.fulfilled, (state, action) => {
          state.updateStatus = 'fulfilled';
        })
        .addCase(updateProduct.pending, (state, action) => {
          state.updateStatus = 'loading';
        })
        .addCase(updateProduct.fulfilled, (state, action) => {
          state.updateStatus = 'fulfilled';
        })
        .addCase(saveDailyUpdates.pending, (state, action) => {
          state.updateStatus = 'pending';
        })
        .addCase(saveDailyUpdates.fulfilled, (state)=> {
          state.updateStatus = 'fulfilled';
        })
        .addCase(getLatestProductStock.pending, (state)=> {
          state.stockFetchStatus = 'pending';
        })
        .addCase(getLatestProductStock.fulfilled, (state, action)=> {
          state.stockFetchStatus = 'fulfilled';
          state.latestProductStock = action.payload;
        })
        .addCase(getDefaultProducts.pending, (state)=> {
          state.stockFetchStatus = 'pending';
        })
        .addCase(getDefaultProducts.fulfilled, (state, action)=> {
          state.stockFetchStatus = 'fulfilled';
          state.defaultProducts = action.payload.defaultProducts;
          state.latestProductStock = action.payload.latestProducts;
          state.productStocks = action.payload.productStocks;
        })
        .addCase(getProductsForDate.pending, (state)=> {
          state.stockFetchStatus = 'pending';
        })
        .addCase(getProductsForDate.fulfilled, (state, action)=> {
          state.stockFetchStatus = 'fulfilled';
          state.productStocks = action.payload.productStocks;
        });
  },
});

export const {
  addToCart,
  increaseCartQty,
  decreaseCartQty,
  clearCart,
  selectCategory,
  showHalfCart,
  setSearchKey,
} = productSlice.actions;
export const allProducts = (state) => state.products.allProducts;
export const productsStatus = (state) => state.products.status;
export const productsUpdateStatus = (state) => state.products.updateStatus;
export const cart = (state) => state.products.basket;
export const totalPrice = (state) => state.products.totalPrice;
export const totalPriceAfterOffer = (state) =>
  state.products.totalPriceAfterOffer;
export const offerPrice = (state) =>state.products.offerPrice;
export const totalPriceFinal = (state) => state.products.totalPriceFinal;
export const isFavorite = (state) => state.products.isFavorite;
export const isEnterOfferCode = (state) => state.products.isEnterOfferCode;
export const offerMessage = (state) => state.products.offerMessage;
export const shippingCharge = (state) => state.products.shippingCharge;
export const allOptions = (state) => state.products.allOptions;
export const selectedCategory = (state) => state.products.selectedCategory;
export const openHalfCart = (state) => state.products.openHalfCart;
export const searchKey = (state) => state.products.searchKey;
export const defaultProducts = (state) => state.products.defaultProducts;
export const latestProductStock = (state) => state.products.latestProductStock;
export const productStockForDate = (state) => state.products.productStockForDate;
export const productStocks = (state) => state.products.productStocks;
export const stockFetchStatus = (state) => state.products.stockFetchStatus;
export const updateStatus = (state) => state.products.updateStatus;

export default productSlice.reducer;
