import 'firebase/auth';
import db from '../../firebaseConfig';
import {
  collection,
  query,
  orderBy,
  limit,
  getDocs,
} from 'firebase/firestore';

/**
* get suppliers
*
* @return {Promise} A promise that returns suppliers
*/
export function getSuppliersList() {
  return new Promise((resolve) => {
    resolve(getSuppliers());
  })
}

const getSuppliers = async() => {
  const getSuppliersQuery = query(
    collection(db, "suppliers"),
    orderBy("name", 'asc')
  );
  const documentSnapshots = await getDocs(getSuppliersQuery);
  const suppliers = [];
  documentSnapshots.forEach((doc) => {
    const data = doc.data();
    suppliers.push({
      id: doc.id,
      name: data.name,
      contactPerson: data.contactPerson,
    });
  });
  return suppliers;
};
