import db from '../../firebaseConfig';
import {
  collection,
  getDocs,
  query,
  where,
  addDoc,
  Timestamp,
} from 'firebase/firestore';

const fetchLocations = async () => {
  const res = [];
  const q = query(collection(db, 'active_locations'));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    const data = doc.data();
    res.push({id: doc.id, ...data});
  });
  return {data: res};
};

/**
 * Fetches all locations.
 *
 * @return {Promise} A promise that resolves with the locations.
 */
export function fetchAllLocation() {
  return new Promise((resolve) =>
    resolve(fetchLocations()),
  );
}

const fetchLocationOwnerById = async (locationId) => {
  try {
    const res = [];
    const q = query(
        collection(
            db,
            'location_owners',
        ),
        where('locationIds', 'array-contains', locationId),
    );
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => res.push(doc.data()));
    return {data: res};
  } catch (error) {
    throw new Error('Login failed. Please try again.');
  }
}

export function fetchLocationOwner(locationId) {
  return new Promise((resolve) =>
    resolve(fetchLocationOwnerById(locationId)),
  );
}

const saveCustomerAddress = async (address) => {
  try {
    const now = new Date();
    const docRef = await addDoc(
        collection(
            db, 'customer_address',
        ),
        {...address, createdAt: Timestamp.fromDate(now)},
    );
    return {
      id: docRef.id,
      ...address,
    };
  } catch (error) {
    throw new Error('Login failed. Please try again.');
  }
};

/**
 * Save Address
 *
 * @param {object} address - The address object to be saved.
 * @return {Promise} A promise to save the address.
 */
export function saveAddress(address) {
  return new Promise((resolve) =>
    resolve(saveCustomerAddress(address)),
  );
}

const fetchCustomerAddress = async (uid) => {
  try {
    const res = [];
    const q = query(
        collection(
            db,
            'customer_address',
        ),
        where('uid', '==', uid),
    );
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => res.push(doc.data()));
    return {data: res};
  } catch (error) {
    throw new Error('Login failed. Please try again.');
  }
};

/**
 * Fetches address.
 *
 * @param {string} uid - The string uid to fetch address.
 * @return {Promise} A promise that resolves with the address.
 */
export function fetchAddress(uid) {
  return new Promise((resolve) =>
    resolve(fetchCustomerAddress(uid)),
  );
}
