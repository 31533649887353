import { configureStore } from '@reduxjs/toolkit';
import orderSlice from '../features/order/orderSlice';
import pageSlice from '../features/page/pageSlice';
import productReducer from '../features/products/productSlice';
import userSlice from '../features/user/userSlice';
import adminSlice from '../features/admin/adminSlice';
import locationSlice from '../features/locations/locationSlice';
import cartSlice from '../features/cart/cartSlice';
import partnerSlice from '../features/partner/partnerSlice';
import suppliersSlice from '../features/suppliers/suppliersSlice';

export const store = configureStore({
  reducer: {
    order: orderSlice,
    page: pageSlice,
    products: productReducer,
    user: userSlice,
    admin: adminSlice,
    locations: locationSlice,
    cart: cartSlice,
    partner: partnerSlice,
    suppliers: suppliersSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false, // Disable serializable check
    }),
});
