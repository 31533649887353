import {firebaseApp} from '../../firebaseConfig';
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
} from 'firebase/auth';
import 'firebase/auth';

import db from '../../firebaseConfig';
import {
  collection,
  query,
  orderBy,
  startAfter,
  limit,
  getDocs,
} from 'firebase/firestore';

const register = async (email, password) => {
  const auth = getAuth(firebaseApp);
  try {
    const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password,
    );
    return userCredential.user;
  } catch (error) {
    throw new Error('register failed.');
  }
};

/**
 * register user.
 *
 * @param {string} email - The string email for register.
 * @param {string} password - The string password for register.
 * @return {Promise} A promise for registerUser.
 */
export function registerUser(email, password) {
  return new Promise((resolve) =>
    resolve(register(email, password)),
  );
}

const login = async (email, password) => {
  const auth = getAuth(firebaseApp);
  try {
    const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password,
    );
    const user = userCredential.user.toJSON();
    return user;
  } catch (error) {
    throw new Error(
        'Login failed.Please check your email and password and try again.',
    );
  }
};

/**
 * login user.
 *
 * @param {string} email - The string email for login.
 * @param {string} password - The string password for login.
 * @return {Promise} A promise for loginUser.
 */
export function loginUser(email, password) {
  return new Promise((resolve) => {
    resolve(login(email, password));
  });
}

/**
 * logout user.
 *
 * @return {Promise} A promise that logout user.
 */
export function logoutUser() {
  return new Promise((resolve) => {
    resolve(logout());
  });
}

const logout = async () => {
  const auth = getAuth(firebaseApp);
  try {
    return await auth.signOut();
  } catch (error) {
    throw new Error('Logout failed');
  }
};

/**
* get users credentials
*
* @return {Promise} A promise that returns users
*/
export function getUsersList(lastName) {
  return new Promise((resolve) => {
    resolve(getUsers(lastName));
  })
}

const getUsers = async(lastName) => {
  let getUserQuery = '';
  if (lastName) {
    getUserQuery = query(collection(db, "users"),
      orderBy("createdAt", 'desc'),
      startAfter(lastName),
      limit(10));
  } else {
    getUserQuery = query(
      collection(db, "users"),
      orderBy("createdAt", 'desc'),
      limit(10)
    );
  }
  const documentSnapshots = await getDocs(getUserQuery);
  const users = [];
  documentSnapshots.forEach((doc) => {
    const data = doc.data();
    const addressArray = data.address;
    users.push({
      id: doc.id,
      uid: data.uid,
      name: data.type ? `${data.name} (${data.type})` : data.name,
      email: data.email,
      mobile: data.mobile,
      address: addressArray.length > 0 ? `${addressArray[0].flatNo} ${addressArray[0].apartmentName}` : '',
      createdAt: data.createdAt,
      partnerUid: data.partnerUid,
    });
  });
  return users;
};
