import * as React from 'react';
import {useSelector} from 'react-redux';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Title from '../Common/Title';
import {todaysOrder} from '../../features/order/orderSlice';

function preventDefault(event) {
  event.preventDefault();
}

export default function Sales() {
  const Orders = useSelector(todaysOrder);
  let total = 0;
  Orders.forEach((doc) => {
    total = total + doc.total_price_final;
  });
  const date = new Date();
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  const formattedDate = date.toLocaleDateString('en-US', options);

  return (
    <React.Fragment>
      <Title>Today's Sales</Title>
      <Typography component="p" variant="h4">
        ₹{total}
      </Typography>
      <Typography color="text.secondary" sx={{ flex: 1 }}>
        {formattedDate}
      </Typography>
      <div>
        <Link color="primary" href="#" onClick={preventDefault}>
          View sales
        </Link>
      </div>
    </React.Fragment>
  );
}
