import db from '../../firebaseConfig';
import {
  collection,
  getDocs,
  query,
  where,
  addDoc,
  Timestamp,
  orderBy,
} from 'firebase/firestore';

const fetchOrderWithDate = async (fromDate, toDate) => {
  const res = []; 
  const q = query(
    collection(db, 'orders'),
    where('createdAt', '>=', fromDate),
    where('createdAt', '<=', toDate),
    orderBy('createdAt', 'asc')
  );

  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    const data = doc.data();
    res.push({id: doc.id, ...data});
  });
  return {data: res};
}

const fetchOrders = async (uid) => {
  const res = [];
  const q = query(collection(db, 'orders'), where('uid', '==', uid));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    const data = doc.data();
    res.push({id: doc.id, ...data});
  });
  return {data: res};
};

/**
 * Fetches all order.
 *
 * @param {string} uid - The string uid to fetch all order.
 * @param {date} date - The string uid to fetch all order.
 * @return {Promise} A promise that resolves with all orders.
 */
export function fetchAllOrders(type, uid, fromDate, toDate) {
  if (type === 'date') {
    return new Promise((resolve) =>
      resolve(fetchOrderWithDate(fromDate, toDate)),
    );
  }
  else if (type === 'customer') {
    return new Promise((resolve) =>
      resolve(fetchOrders(uid)),
    );
  } else if (type === 'orderId') {
    return new Promise((resolve) =>
      resolve(fetchOrders(uid)),
    );
  }
}

const saveOrder = async (order) => {
  try {
    const now = new Date();
    const docRef = await addDoc(
        collection(db, 'orders'),
        {...order, createdAt: Timestamp.fromDate(now)},
    );
    const id = docRef.id;
    return {
      status: 'success',
      order: {...order, id},
    };
  } catch (error) {
    return {
      status: 'failed',
      order,
    };
  }
};

/**
 * Place order.
 *
 * @param {string} order - The Object order to be saved.
 * @return {Promise} A promise that saves the order.
 */
export function placeOrder(order) {
  return new Promise((resolve) =>
    resolve(saveOrder(order)),
  );
}

const fetchSelectedOrder = async (orderId) => {
  try {
    const res = [];
    const q = query(collection(db, 'orders'), where('uid', '==', orderId));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => res.push(doc.data()));
    return {data: res};
  } catch (error) {
    throw new Error('Login failed. Please try again.');
  }
};

/**
 * Fetches order.
 *
 * @param {string} orderId - The string uid to fetch order.
 * @return {Promise} A promise that resolves with the order.
 */
export function fetchOrder(orderId) {
  return new Promise((resolve) =>
    resolve(fetchSelectedOrder(orderId)),
  );
}
