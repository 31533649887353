import db from '../../firebaseConfig';
import {
  collection,
  getDocs,
  query,
  where,
  orderBy,
} from 'firebase/firestore';

const fetchCartWithDate = async (fromDate, toDate) => {
  const res = []; 
  const q = query(
    collection(db, 'cart'),
    where('createdAt', '>=', fromDate),
    where('createdAt', '<=', toDate),
    orderBy('createdAt', 'asc')
  );

  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    const data = doc.data();
    res.push({id: doc.id, ...data});
  });
  return {data: res};
}

const fetchCart = async (uid) => {
  const res = [];
  const q = query(collection(db, 'cart'), where('uid', '==', uid));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    const data = doc.data();
    res.push({id: doc.id, ...data});
  });
  return {data: res};
};

/**
 * Fetches all cart.
 *
 * @param {string} uid - The string uid to fetch all cart.
 * @param {date} date - The string uid to fetch all cart.
 * @return {Promise} A promise that resolves with all cart.
 */
export function fetchAllCart(type, uid, fromDate, toDate) {
  if (type === 'date') {
    return new Promise((resolve) =>
      resolve(fetchCartWithDate(fromDate, toDate)),
    );
  }
  else if (type === 'customer') {
    return new Promise((resolve) =>
      resolve(fetchCart(uid)),
    );
  } else if (type === 'cartId') {
    return new Promise((resolve) =>
      resolve(fetchCart(uid)),
    );
  }
}

const fetchSelectedCart = async (cartId) => {
  try {
    const res = [];
    const q = query(collection(db, 'cart'), where('uid', '==', cartId));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => res.push(doc.data()));
    return {data: res};
  } catch (error) {
    throw new Error('Login failed. Please try again.');
  }
};

/**
 * Fetches cart.
 *
 * @param {string} cartId - The string uid to fetch cart.
 * @return {Promise} A promise that resolves with the cart.
 */
export function fetchSingleCart(cartId) {
  return new Promise((resolve) =>
    resolve(fetchSelectedCart(cartId)),
  );
}
