import * as React from 'react';
import {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import Container from '@mui/material/Container';
import Button  from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { Select, MenuItem } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import CircularProgress from '@mui/material/CircularProgress';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import './Inventory.css';
import {
  stockFetchStatus,
  saveDailyUpdates,
  productStocks,
  getProductsForDate,
  updateStatus,
} from '../../features/products/productSlice';
import {
  getSuppliers,
  suppliersList
} from '../../features/suppliers/suppliersSlice';

function InventoryContent() {
  const dispatch = useDispatch();

  const fetchStatus = useSelector(stockFetchStatus);
  const saveStatus = useSelector(updateStatus);
  const stockProducts = useSelector(productStocks);
  const suppliers = useSelector(suppliersList);
  const [formData, setFormData] = useState(stockProducts);
  const [selectedDate, setSelectedDate] = useState(null);
  const [open, setOpen] = useState(false);
  const [openTodaysUpdate, setOpenTodaysUpdate] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [productText, setProductText] = useState("");


  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenTodaysUpdate = () => {
    setOpenTodaysUpdate(true);
  };

  const handleCloseTodaysUpdate = () => {
    setOpenTodaysUpdate(false);
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  useEffect(() => {
    if(fetchStatus === 'fulfilled') {
      setFormData(stockProducts);
    }
  }, [dispatch, fetchStatus]);

  const onProceed = () => {
    setIsConfirmationModalOpen(false);
    const changedProducts = productTextToObject(productText);
    if(selectedDate !== null) {
      dispatch(saveDailyUpdates({
        docId: selectedDate.format("DD_MM_YYYY"),
        dailyUpdates: changedProducts,
      }));
    }
  }

  const closeConfirmationModal = () => {
    setIsConfirmationModalOpen(false);
  };

  const copyContent = `${selectedDate ? selectedDate.format("DD/MM/YYYY") : ''}\n${
    formData
      .filter(product => typeof product.salesPrice === 'number' && typeof product.qty === 'number' && product.qty !== 0)
      .map(product => {
        const formattedSalesPrice = product.salesPrice || 0;
        return `${product.id}\t${product.product_name}\t${formattedSalesPrice}`;
      })
      .join('\n')
  }`;

  const copyContentTodaysUpdate = `${selectedDate ? selectedDate.format("DD/MM/YYYY") : ''}\n` +
  `ID\tName\tQty\tPurchase Price (Rate)\tTotal Purchase Price\tSales Price\tTotal Sales Price\tProfit\n${
    formData
      .filter((product) => {
        const originalProduct = stockProducts.find((stockProduct) => stockProduct.id === product.id);
        return Object.keys(product).some((field) => product[field] !== originalProduct[field]);
      })
      .filter(product => typeof product.salesPrice === 'number' && typeof product.qty === 'number' && product.qty !== 0)
      .map(product => {
        const formattedSalesPrice = product.salesPrice || 0;
        return `${product.id}\t${product.product_name}\t${product.qty}\t${product.rate}\t${product.purchasePrice}\t${formattedSalesPrice}\t${product.totalSalesPrice}\t${product.profit}`;
      })
      .join('\n')
  }`;

  const ConfirmationModal = ({ isOpen, onClose }) => {
    const changedProducts = productTextToObject(productText);
    if(changedProducts) {
      return (
        <Dialog open={isOpen} onClose={onClose}>
          <DialogTitle className='dialog-title'>Confirmation the updated you made</DialogTitle>
          <DialogContent>
            {changedProducts.map((product, index) => (
              <div key={index}>
                <p>
                  <span className="qty-text">{product.id} </span>
                  <span className="name-text">{product.product_name} </span>
                  <span className="qty-text">{product.qty} </span>
                  <span className="purchase-price-text">₹{product.purchasePrice} </span>
                  <span className="sales-price-text">₹{product.salesPrice} </span>
                  <span className="purchase-rate-text">₹{product.rate} </span>
                  <span className="purchase-price-text">₹{product.profit} </span>
                </p>
              </div>
            ))}
          </DialogContent>
          <DialogActions className='dialog-actions'>
            <Button onClick={onClose}>Need correction</Button>
            <Button 
              onClick={onProceed}
              variant="contained"
              color="primary"
              className='save-btn'
            >Proceed and Saving</Button>
          </DialogActions>
        </Dialog>
      );
    }
  };

  const productTextToObject = (productTxt) => {
    if(productTxt !=="") {
      const lines = productTxt.trim().split('\n');
      const products = [];

      lines.forEach(line => {
          const fields = line.split('\t');
          const id = parseInt(fields[1]);
          const product_name = fields[2];
          const qty = parseInt(fields[4]);
          let purchasePrice = 0;
          let salesPrice = 0;
          let totalSalesPrice = 0;
          let rate = 0;
          let profit = '0%';
          if(qty > 0) {
            purchasePrice = parseFloat(fields[5].replace('₹', '').replace(',', ''));
            salesPrice = parseFloat(fields[6].replace('₹', '').replace(',', ''));
            totalSalesPrice = qty * salesPrice;
            rate = Number(parseFloat(purchasePrice / qty).toFixed(2));
            profit = ((((totalSalesPrice)) - purchasePrice) * 100 / purchasePrice).toFixed(2) + "%";
          }
          const product = {
              id,
              product_name,
              qty,
              purchasePrice,
              salesPrice,
              totalSalesPrice,
              rate,
              profit,
          };
          products.push(product);
      });
      return products;
    }
  };
  
  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }} className='inventory'>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          value={selectedDate}
          onChange={(newValue) => {
            dispatch(getSuppliers());
            dispatch(getProductsForDate(newValue.format("DD_MM_YYYY")));
            setSelectedDate(newValue);
          }}
          format="DD-MM-YYYY"
        />
      </LocalizationProvider>
      <div className="textarea-container">
        <textarea
          onChange={(e) =>
            setProductText(e.target.value)
          }
        ></textarea>
        <Button
          onClick={() => setIsConfirmationModalOpen(true)}
        >Save Products</Button>
      </div>
      {saveStatus === 'fulfilled'? (
        "Product updated successfully!!!"
        ) : null}
      <ConfirmationModal
        isOpen={isConfirmationModalOpen}
        onClose={closeConfirmationModal}
      />
    </Container>
  );
}

export default function InventoryForm2() {
  return <InventoryContent />;
}
