import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {getSuppliersList} from './suppliersAPI';

const initialState = {
  status: 'idle',
  suppliersList: [],
};

export const getSuppliers = createAsyncThunk(
  'partner/getPartners',
  async() => {
    return await getSuppliersList();
  }
);

export const suppliersSlice = createSlice({
  name: 'suppliers',
  initialState,
  extraReducers: (builder) => {
    builder
        .addCase(getSuppliers.pending, (state) => {
          state.status = 'loading';
        })
        .addCase(getSuppliers.fulfilled, (state, action) => {
          state.suppliersList = action.payload;
          state.status = 'fulfilled';
        });
  },
});

export const suppliersList = (state) => state.suppliers.suppliersList;
export default suppliersSlice.reducer;
