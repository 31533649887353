import React from 'react';
import LoginEmail from './LoginEmail';
import './Login.css';

/**
 * Component that displays the login page
 *
 * @return {JSX.Element} Login component JSX
 */
export default function Login() {
  return (
    <LoginEmail/>
  );
}
