import * as React from 'react';
import {useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {getOrderForDate, orderForTheDate, getAllOrders, allOrders} from '../../features/order/orderSlice';
import { selectPage, selectedData }  from '../../features/page/pageSlice';

function createData(id, date, name, shipTo, paymentMethod, amount) {
  return { id, date, name, shipTo, paymentMethod, amount };
}

function OrdersContent() {
  const dispatch = useDispatch();
  const customerData = useSelector(selectedData);
  const orderPageType = customerData && customerData.type ? customerData.type : 'date_selection';

  const handleDateChange = (date) => {
    const now = new Date(date);
    const fromDate = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const toDate = new Date(fromDate);
    toDate.setDate(toDate.getDate() + 1);
    dispatch(getOrderForDate({fromDate, toDate}));
  }

  useEffect(() => {
    if(orderPageType === 'customer_order') {
      dispatch(getAllOrders(customerData.data.uid));
    } else {
      const now = new Date();
      const fromDate = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      const toDate = new Date(fromDate);
      toDate.setDate(toDate.getDate() + 1);
      dispatch(getOrderForDate({fromDate, toDate}));
    }
  }, [dispatch]);

  const dateWiseOrders = useSelector(orderForTheDate);
  const customerOrders = useSelector(allOrders);
  const orders = orderPageType === 'customer_order' ? customerOrders : dateWiseOrders;

  const rows = [];
  orders.forEach((doc) => {
    const orderDate = doc.createdAt.toDate();
    const formattedDate = orderDate.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' });
    const formattedTime = orderDate.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
    const formattedDateTime = `${formattedDate} ${formattedTime}`;
    rows.push(createData(
      doc.id,
      formattedDateTime,
      doc.address.name,
      doc.address.name,
      'COD',
      doc.total_price_final,
    ));
  });

  const orderClick = (id) => {
    dispatch(selectPage({page: 'ORDER_DETAILS', data : id}));
  }

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={3}>
        {/* Recent Orders */}
        <Grid item xs={12} md={8} lg={4}>
          <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', mb: 1 }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                defaultValue={dayjs()}
                format="DD/MM/YYYY"
                label="Select a date"
                inputFormat="DD/MM/YYYY"
                onChange={handleDateChange}
              />
            </LocalizationProvider>
          </Paper>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        {/* Recent Orders */}
        <Grid item xs={12}>
          <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
            <TableContainer component={Paper}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Date</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Ship To</TableCell>
                    <TableCell>Payment Method</TableCell>
                    <TableCell align="right">Sale Amount</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <TableRow key={row.id} onClick={()=>orderClick(row.id)} className="order-row">
                      <TableCell>{row.date}</TableCell>
                      <TableCell>{row.name}</TableCell>
                      <TableCell>{row.shipTo}</TableCell>
                      <TableCell>{row.paymentMethod}</TableCell>
                      <TableCell align="right">{`₹${row.amount}`}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
}

export default function Orders() {
  return <OrdersContent />;
}
