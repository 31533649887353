import db from '../../firebaseConfig';
import {
  collection,
  getDocs,
  query,
  where,
  doc,
  runTransaction,
  addDoc,
  orderBy,
  limit,
  updateDoc,
  setDoc,
  getDoc,
  serverTimestamp,
} from 'firebase/firestore';

const getProducts = async (category) => {
  const res = [];
  const q = category ?
    query(collection(db, 'products'), where('category', '==', category)) :
    query(collection(db, 'products'));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    const data = doc.data();
    res.push({id: doc.id, ...data});
  });
  return {data: res};
};

/**
 * fetch all products.
 *
 * @param {string} category - The string category to fetch products.
 * @return {Promise} A promise that resolves with products.
 */
export function fetchProducts(category) {
  const productsPromise = getProducts(category);
  return Promise.all([productsPromise])
      .then(([products]) => {
        return {data: {products}};
      });
}

const getProductStocks = async (docId) => {
  try {
    const docRef = doc(collection(db, 'daily_stock'), docId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const data = docSnap.data();
      if(data.data) {
        return [...data.data];
      }
      return [];
    } else {
      return [];
    }
  } catch (error) {
    return { error };
  }
};

export function fetchProductStocks() {
  const defaultProductsPromise = getProductStocks('default');
  const productsPromise = getProducts();
  //const selectedDateProductStockPromise = getProductStocks(docId);
  //const latestProductStockPromise = getLatestProductStock();
  return Promise.all([defaultProductsPromise, productsPromise])
      .then(([defaultProducts, products]) => {
        const transformedProducts = products.data.map((product) => {
          const isNotValidQty = product.qty_available === 0 || isNaN(product.qty_available);
          const totalSalesPrice = isNotValidQty ? "" : product.list_price * product.qty_available;
          const profit = isNotValidQty ?
            "" :
            `${(((product.list_price - product.standard_price) / product.list_price) * 100).toFixed(2)}%`;

          return {
            totalSalesPrice,
            rate: product.standard_price,
            salesPrice: product.list_price,
            profit,
            product_name: product.title,
            id: Number(product.internal_reference),
            qty: isNotValidQty ? "" : product.qty_available,
            purchasePrice: isNotValidQty ? "" : (product.standard_price * product.qty_available).toFixed(0),
          };
        });

        const productStocks = defaultProducts.map(defaultProduct => {
          const transformedProduct = transformedProducts.find(transformedProduct => transformedProduct.id === defaultProduct.id);
        
          return {
            ...defaultProduct,
            ...(transformedProduct || {}),
          };
        });

        return {productStocks};
      });
}

const getLatestProductStock = async () => {
  try {
    const collectionRef = collection(db, 'daily_stock');
    const q = query(collectionRef, orderBy('timestamp', 'desc'), limit(1));
  
    const querySnapshot = await getDocs(q);
  
    // Access the latest document
    const latestDocument = querySnapshot.docs[0].data();
    return latestDocument.data;
  } catch (error) {
    return [];
  }
};

export function fetchLatestProductStock() {
  return new Promise((resolve) => {
    resolve(getLatestProductStock());
  });
}

const updateProduct = async (optionId) => {
  try {
    const sfDocRef = doc(db, 'product_options', optionId);
    try {
      await runTransaction(db, async (transaction) => {
        const sfDoc = await transaction.get(sfDocRef);
        if (!sfDoc.exists()) {
          throw new Error('Document does not exist!');
        }

        const newQty = sfDoc.data().quantity - 1;
        if (newQty > 0) {
          transaction.update(sfDocRef, {quantity: newQty});
          return newQty;
        } else {
          return Promise.reject(new Error('Sorry! out of stock'));
        }
      });
      return {optionId, status: 'updated'};
    } catch (e) {
      // This will be a "out of stock" error.
      console.error(e);
    }
  } catch (error) {
    throw new Error('Login failed. Please try again.');
  }
};

/**
 * update product availability.
 *
 * @param {string} optionIds - The array of option ids to update availability.
 * @return {Promise} A promise that updates the availability.
 */
export function updateProductAvailability(optionIds) {
  const promises = optionIds.map((optionId) => updateProduct(optionId));
  return Promise.all(promises);
}

const saveProduct = async (product) => {
  const collectionRef = collection(db, "products");
  await addDoc(collectionRef, product);
}

export function addProduct (product) {
  return new Promise((resolve) =>
    resolve(saveProduct(product)),
  );
}

const saveDailyUpdates = async (docId, dailyStock) => {
  try {
    const collectionRef = collection(db, "daily_stock");
    const docRef = doc(collectionRef, docId);
  
    // Retrieve the existing document
    const docSnapshot = await getDoc(docRef);
    const existingData = docSnapshot.exists() ? docSnapshot.data().data : [];
  
    // Merge the arrays in your application code
    const mergedData = mergeArrays(existingData, dailyStock);
  
    // Update the document with the merged data
    const timestamp = serverTimestamp();
    await setDoc(docRef, {
      data: mergedData,
      timestamp,
    }, { merge: true });
    console.log('Document successfully updated or created!');
  } catch (error) {
    console.log('Error updating or creating document: ', error);
  }
}

// Helper function to merge arrays based on a unique identifier (e.g., id)
function mergeArrays(existingArray, newArray) {
  const mergedMap = new Map(existingArray.map(item => [item.id, item]));

  for (const newItem of newArray) {
    mergedMap.set(newItem.id, { ...mergedMap.get(newItem.id), ...newItem });
  }

  return Array.from(mergedMap.values());
}

export function addDailyUpdates (docId, dailyStock) {
  const saveDailyUpdatesPromise = saveDailyUpdates(docId, dailyStock);
  const updateProductStockPromises = dailyStock.map((stock) => {
    return updateProductStock(stock);
  });
  const allPromises = [...updateProductStockPromises, saveDailyUpdatesPromise];
  return Promise.all(allPromises);
}

const updateProductStock = async (stock) => {
  const productCollectionRef = collection(db, 'products');
  const productQuery = query(productCollectionRef, where('internal_reference', '==', Number(stock.id)), limit(1));
  const productQuerySnapshot = await getDocs(productQuery);
  if (!productQuerySnapshot.empty) {
    const doc = productQuerySnapshot.docs[0]; // Get the first (and only) document
    const docRef = doc.ref;
    const docSnapshot = await getDoc(docRef);
    if (docSnapshot.exists()) {
      const baseSalesPrice = isNaN(stock.salesPrice) ? 0 : stock.salesPrice;
      const quantity = isNaN(stock.qty) ? 0 : stock.qty;
      const data = docSnapshot.data();
      const productOptions = data.product_options;
      const updatedProductOptions = productOptions.map(option => {
        const weightRatio = option.weight_ratio;
        const sellPrice = baseSalesPrice * weightRatio;
        // Return the option with the updated sellPrice
        return {
          ...option,
          sellPrice: sellPrice
        };
      });

      try {
        await updateDoc(docRef, {
          qty_available: Number(quantity),
          list_price: Math.ceil(Number(baseSalesPrice)),
          standard_price: Math.ceil(Number(stock.rate)),
          product_options: updatedProductOptions,
        });
      } catch (err) {
        console.error(err);
      }
    }
  }
};

const setDocument = async (collection, id, data) => {
  const docRef = doc(db, collection, id);
  await updateDoc(docRef, data).then(docRef => {
    return docRef;
  })
  .catch(error => {
    console.log(error);
  });
}

export function updateDocument (collection, id, data) {
  return new Promise((resolve) =>
    resolve(setDocument(collection, id, data).then(() => {
      return data;
    }).catch((err) => {
      console.log(err);
    })),
  );
}
