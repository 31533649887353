import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {fetchAllLocation, saveAddress, fetchAddress} from './locationAPI';

const initialState = {
  allLocations: [],
  status: 'idle',
  customerAddresses: [],
  selectedAddress: {},
};

export const getLocations = createAsyncThunk(
    'locations/fetchLocations',
    async () => {
      const response = await fetchAllLocation();
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    },
);

export const saveCustomerAddress = createAsyncThunk(
    'locations/saveCustomerAddress',
    async (address) => {
      return await saveAddress(address);
    },
);

export const fetchCustomerAddress = createAsyncThunk(
    'locations/fetchCustomerAddress',
    async (uid) => {
      const response = await fetchAddress(uid);
      return response.data;
    },
);

export const locationSlice = createSlice({
  name: 'locations',
  initialState,
  // The `reducers` field lets us define reducers
  // and generate associated actions
  reducers: {
    setSelectedAddress: (state, action) => {
      // state[selectedAddress] = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
        .addCase(getLocations.pending, (state) => {
          state.status = 'loading';
        })
        .addCase(getLocations.fulfilled, (state, action) => {
          state.status = 'idle';
          state.allLocations = action.payload;
        })
        .addCase(saveCustomerAddress.pending, (state) => {
          state.status = 'pending';
        })
        .addCase(saveCustomerAddress.fulfilled, (state, action) => {
          state.status = 'fulfilled';
          state.customerAddresses.push(action.payload);
          state.selectedAddress = action.payload;
        })
        .addCase(fetchCustomerAddress.pending, (state) => {
          state.status = 'pending';
        })
        .addCase(fetchCustomerAddress.fulfilled, (state, action) => {
          state.status = 'fulfilled';
          state.status = 'fetchCustomerAddress';
          const customerAddresses = action.payload;
          state.selectedAddress = customerAddresses.length > 0 ?
            customerAddresses[0] :
            {};
          state.customerAddresses = customerAddresses;
        });
  },
});

export const {setSelectedAddress} = locationSlice.actions;
export const allLocations = (state) => state.locations.allLocations;
export const customerAddresses = (state) => state.locations.customerAddresses;
export const selectedAddress = (state) => state.locations.selectedAddress;

export default locationSlice.reducer;
