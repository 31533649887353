import * as React from 'react';
import {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import Container from '@mui/material/Container';
import { DataGrid } from '@mui/x-data-grid';
import Button  from '@mui/material/Button';
import { getUsers, usersList } from '../../features/user/userSlice';
import {getPartnersByUid, partners} from '../../features/partner/partnerSlice';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import './Customers.css';
import { selectPage }  from '../../features/page/pageSlice';

function UserContent() {
  const dispatch = useDispatch();
  const [firstRow, setFirstRow] = useState(0);
  const users = useSelector(usersList);
  const partnersList = useSelector(partners);

  useEffect(() => {
    dispatch(getUsers());
  }, []);

  useEffect(() => {
    if(users.length > 0) {
      const partnerUidArray = [...new Set(users.map(user => user.partnerUid))];
      dispatch(getPartnersByUid(partnerUidArray));
    }
  }, [users]);

  
  const currentPageUsers = users.slice(firstRow, firstRow + 10);

  const getPartnerName = (puid) => {
    if(partnersList.length > 0) {
      const partner = partnersList.find(p => p.partnerUid === puid)
      return partner ? partner.name : '';
    }
    return '';
  };

  const columns = [
    {
      field: "View Orders",
      renderCell: (cellValues) => {
        return (
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              handleClick(cellValues);
            }}
          >
            Orders
          </Button>
        );
      }
    },
    { field: 'name', headerName: 'Name', width: 250 },
    { field: 'createdAt', headerName: 'Created', renderCell: (cellValues) => {
      return (
        cellValues.value.toDate().toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' })
      );
    } },
    { field: 'email', headerName: 'Email', width: 200 },
    { field: 'mobile', headerName: 'Mobile', width: 100 },
    { field: 'partnerUid', headerName: 'Partner', renderCell: (cellValues) => {
      return (
        getPartnerName(cellValues.value)
      );
    } },
    { field: 'address', headerName: 'Address', width: 350 },
  ];

  const handleClick = (cellValues) => {
    dispatch(selectPage({page: 'ORDERS', data : {type: 'customer_order', data: cellValues.row}}));
  };

  const handleBackClick = () => {
    setFirstRow(firstRow > 0 ? firstRow - 10 : 0);
  };

  const handleForwardClick = () => {
    if (users.length > 0) {
      const lastUser = users[users.length - 1];
      dispatch(getUsers(lastUser.createdAt));
      setFirstRow(firstRow + 10); 
    }
  };
  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }} className='inventory'>
      <DataGrid rows={currentPageUsers} columns={columns} />
      <div className="pagination">
        <ArrowBackIosIcon onClick={handleBackClick} className={firstRow === 0 ? 'disabled' : ''} />
        <ArrowForwardIosIcon onClick={handleForwardClick} className={currentPageUsers.length < 10 ? 'disabled' : ''} />
      </div>
    </Container>
  );
}

export default function Inventory() {
  return <UserContent />;
}
