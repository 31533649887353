import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {fetchAllCart, fetchSingleCart} from './cartAPI';

const initialState = {
  allCart: [],
  status: 'idle',
  selectedCart: {},
  currentCart: {},
  createCartStatus: 'idle',
  todaysCart: [],
  cartForTheDate: [],
};

/**
 * get cart for customers.
 * @param {object} allCart - The cart object to save.
 * @return {Promise<void>} A promise that resolves when
 * the cart has been saved.
 */
export const getAllCart = createAsyncThunk(
    'cart/fetchAllCart',
    async (uid) => {
      const response = await fetchAllCart('customer', uid);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    },
);

/**
 * Saves an Cart to the server.
 * @param {object} allCart - The Cart object to save.
 * @return {Promise<void>} A promise that resolves when
 * the Cart has been saved.
 */

export const getCartForDate = createAsyncThunk(
  'Cart/getCartForDate',
  async ({fromDate, toDate}) => {
    const response = await fetchAllCart('date', null, fromDate, toDate);
    // The value we return becomes the `fulfilled` action payload
    return response.data;
  },
);

/**
 * Saves an Cart to the server.
 * @param {object} selectedCart - The Cart object to save.
 * @return {Promise<void>} A promise that resolves when the
 * Cart has been saved.
 */
export const getSelectedCart = createAsyncThunk(
    'Cart/fetchSingleCart',
    async (cartId) => {
      return await fetchSingleCart(cartId);
    },
);

export const cartSlice = createSlice({
  name: 'cart',
  initialState,
  // The `reducers` field lets us define reducers
  // and generate associated actions
  reducers: {},
  extraReducers: (builder) => {
    builder
        .addCase(getAllCart.pending, (state) => {
          state.status = 'loading';
        })
        .addCase(getAllCart.fulfilled, (state, action) => {
          state.status = 'idle';
          state.allCart = action.payload;
        })
        .addCase(getSelectedCart.pending, (state) => {
          state.status = 'pending';
        })
        .addCase(getSelectedCart.fulfilled, (state, action) => {
          state.status = 'fulfilled';
          state.selectedCart = action.payload;
        })
        .addCase(getCartForDate.pending, (state) => {
          state.status = 'pending';
        })
        .addCase(getCartForDate.fulfilled, (state, action) => {
          state.status = 'fulfilled';
          state.todaysCart = action.payload;
          state.cartForTheDate = action.payload;
        });
  },
});

export const allCart = (state) => state.cart.allCart;
export const selectedCart = (state) => state.cart.selectedCart;
export const currentCart = (state) => state.cart.currentCart;
export const createCartStatus = (state) => state.cart.createCartStatus;
export const todaysCart = (state) => state.cart.todaysCart;
export const cartForTheDate = (state) => state.cart.cartForTheDate;

export default cartSlice.reducer;
